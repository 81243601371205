<template>
  <v-card class="ma-0" :maxHeight="height - 24" :minHeight="minHeight">
    <v-card-title class="secondary white--text py-0" @click="$parent.fitchData()">
      <slot name="title"></slot>
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-else>
        <v-card-text :style="`height: ${height + ($vuetify.breakpoint.smAndDown ? -14 : -38)}px; overflow: auto`" class="pa-0">
          <a-table-f-api
            v-if="typeof api == 'string'"
            ref="tableWidget"
            :api="api"
            :model="model"
            :searchable="false"
            :footerHide="footerHide"
            :useQuery="false"
            :defaults="defaults"
            @click="onClick($event)"
            @rowContextMenu="$emit('rowContextMenu', $event)"
          />
          <a-table-f-api-post
            v-else
            ref="tableWidget"
            :apiPost="{ api: '/report_sql/filters', params: api }"
            :model="model"
            :searchable="false"
            :footerHide="footerHide"
            :useQuery="false"
            :defaults="defaults"
            @click="onClick($event)"
            @rowContextMenu="$emit('rowContextMenu', $event)"
          />
        </v-card-text>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {
    height: { type: Number, default: 0 },
    defaults: Object,
    api: [Object, String],
    model: Array,
    isLoading: { type: Boolean, default: false },
    footerHide: { type: Boolean, default: true },
  },
  data() {
    return {
      contentHeight: 10,
      hideViewed: true,
      showReportDialog: false,
      idReport: 0,
      data: [],
      showConfig: false,
      timeID: null,
    };
  },
  created() {
    // console.log(this.sql);
  },
  computed: {
    loading: {
      get() {
        return this.isLoading;
      },
      set(v) {
        this.$emit("loading", v);
      },
    },
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
  },
  watch: {},
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
    updateRowData(id) {
      this.loading = true;
      if (id) this.$refs.tableWidget.updateRowData(id);
      this.loading = !true;
    },
  },
};
</script>
